*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    background-color: #F3F3F3;
    margin: 0;
}

.container .ql-editor {
    width: 100%;
    min-height: 11in;
    padding: 1in;
    margin: 1rem;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    background-color: white;
}

.container .ql-container.ql-snow {
    border: none;
    display: flex;
    justify-content: center;
}

.container .ql-toolbar.ql-snow {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #F3F3F3;
    border: none;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
}

@page {
    margin: 1in;
}

@media print {
    body {
        background: none;
    }

    .container .ql-editor {
        width: 6.5in;
        height: 9in;
        padding: 0;
        margin: 0;
        box-shadow: none;
        align-self: flex-start;
    }

    .container .ql-toolbar.ql-snow {
        display: none;
    }
}